import React, { useState } from "react";
import { authMethods } from "../firebase/authmethods";

export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {

  const [email, setEmail] = useState(
    window.localStorage.getItem("emailForSignIn") || ""
  );

  const [errorResponse, setErrorResponse] = useState("");

  const initState = { email: "", password: "", license:"" };
  const [inputs, setInputs] = useState(initState);
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [paidUser, SetPaidUser] = useState(false);
  const [paymentFlag, setPaymentFlag] = useState(false);

  const handleSigninGoogleAfterRedirect = (user) => {
    authMethods.signinGoogleAfterRedirect(user, setErrors, setToken,setCurrentUser,paymentFlag, setPaymentFlag  )
  }

  const handlesigninGoogle = () => {
      authMethods.signinGoogle( setErrors, setToken);
  }

  const handleSignup = () => {
    // middle man between firebase and signup
    // calling signup from firebase server
    authMethods.signup(inputs.email, inputs.password,inputs.license,  setErrors, setToken);

  };

  const handleSignupLicense = () => {
    // middle man between firebase and signup
    // calling signup from firebase server
    authMethods.signup(inputs.email, inputs.password, setErrors, setToken);

  };

  const handleSignin = () => {
    // made signup signin
    authMethods.signin(inputs.email, inputs.password, setErrors, setToken);
    
  };

  const handleSignout = () => {
    authMethods.signout(setErrors, setToken);
  };


  const handleForgotPassword =() => {
    authMethods.forgotPassword(inputs.email)

 }

  const handleSigninEmailLink = () => {
    alert(inputs.email);
    authMethods.sendEmailLink(inputs.email, setErrors, setToken);
  };
  return (
    <firebaseAuth.Provider
      value={{
        //replaced test with handleSignup
        handleSignup,
        handleSignin,
        token,
        inputs,
        currentUser,
        setInputs,
        errors,
        handleSignout,
        handleSigninEmailLink,
        handleForgotPassword,
        handlesigninGoogle,
        handleSigninGoogleAfterRedirect,
        handleSignupLicense,
        paymentFlag, 
        setCurrentUser,
        setPaymentFlag
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
