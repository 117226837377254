import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { firebaseAuth } from "./provider/AuthProvider";



import Heading from "./components/Heading";

import SignUp from "./components/SignUp";
import Payment from "./components/Payment";
import VideoSection from "./components/VideoSection";

import SignInMagicLink from "./components/SignInMagicLink";
import MagicLinkWait from "./components/MagicLinkWait";
import SignIn from "./components/SignIn";
import SignInEmail from "./components/SignInEmail";
import SignInPlusPurchase from "./components/SignInPlusPurchase";


import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import ForgotPassword from "./components/ForgotPassword";
import GeogebraDraw from "./components/GeogebraDraw";



import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Demo from './components/SimpleVideoCard';
import ImageCard from './components/ImageCard';
import OverviewFlow from './components/OverviewFlow';
import GridList from './components/GridList';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MessageModal from  "./components/MessageModal";
import ButtonMailto from "./components/ButtonMailto.js";
import WhoAmI from "./components/WhoAmI.js";
import Sample from "./components/Pdf/Sample.jsx";
// import InLine from "./components/InLine"; 
// const About = lazy(() => importMDX('./pages/About.mdx'));
import { Portal } from "react-portal";
import YouTube from "react-youtube";


import CardsForBlogs from "./CardsForBlogs"
import AppHeader from "./components/AppHeader";
import SignUpLicense from "./components/SignUpLicense";
import AreaMap1 from "./components/AreaMap1"
import AreaMap2 from "./components/AreaMap2"
import Login from "./components/Login.tsx";
import { createCheckoutSession } from "./stripe/createCheckoutSession";
import usePremiumStatus from "./stripe/usePremiumStatus"
import isPaidUserTest from "./stripe/isPaidUserTest"
import useLocalStorage from "./firebase/uselocalstorage"
import { Redirect,  useLocation } from "react-router-dom";
import Link from '@mui/material/Link';
import {
  useHistory
} from "react-router-dom";


import OpenVideo from "./components/OpenVideo";
import ReactPlayer from 'react-player'

import OpenPopup from "./components/OpenPopup";


const darkTheme = createMuiTheme({
  palette: {
    type: "dark"
  },
  typography: {
    subtitle1: {
      fontSize: 12
    },
    body1: {
      fontWeight: 500
    }
  }
});

const componentsMap = {
  h1: Heading.h3, // mapping h3 to h1 so we're having a smaller font-size
  h2: Heading.h4
};

function SubHeading(props) {

  return (
    <>
    <Grid container >
    <Grid item xs={12} sx={{
        // mt: 250,
    bgcolor: '#1E1E1E',
    width: 100,
    p: 1,
    // m: 1,
    borderRadius: 1,
    textAlign: 'center',
  }}>
    <Typography variant="h3" gutterBottom component="div"  color="#90caf9">
       {props.title}
    </Typography>
    </Grid>
  </Grid>

  </>
  )
}

let blogs_chain
export default function MathApp() {

  const [isDrawerOpen, SetIsDrawerOpen] = useState(false);
  const [userProfile, SetuserProfile] =  useState("notbeginner")
  const { token, handleSignout, inputs, currentUser , setCurrentUser, setPaymentFlag,paymentFlag } = useContext(firebaseAuth);

  const userIsPremium = usePremiumStatus(currentUser);


  const [currentUsertest, setCurrentUserTest] = useState(null);
  let history = useHistory();


  useEffect(() => {

    firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        // alert(JSON.stringify(authUser.uid))
        // alert(JSON.stringify("current user " + currentUser))
        // setCurrentUser(authUser.uid);
        setPaymentFlag(window.localStorage.getItem('paymentFlag'))

      } else {
        setCurrentUser(null);
      }
    })
    // localStorage.setItem("clicked_buy", false)

  }, []);


  let mathTopicsJSON =[]
  debugger;
  try {

    mathTopicsJSON = require(`./pages_posts`).default();
    blogs_chain = _.chain(mathTopicsJSON)
    // Group the elements of Array based on `color` property
    .groupBy("category")
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({ category: key, blogs: value }))
    .value()
    console.log(blogs_chain)

  } catch (err) {}

  const handleDrawerClick = () => {
    // SetIsDrawerOpen(false);
    SetIsDrawerOpen(prevCheck => !prevCheck);
  };




  const handleIsbeginnerClick =(profile) => {
    SetuserProfile(profile)
  }

  const clicked_on = (shape) => 
{
	alert ( "You clicked on the " + shape );
}

 const  ggbParameters = {
    // material_id: "",
    // width: "100%",
    // height: "100%",
    borderColor: "#1E1e1E",
    enableShiftDragZoom: false,
    showToolBar: false
  };


  
  const MyDynamicButton = () => {
    const [count, setCount] = useState(0)
    const [show, setShow] = useState(false)
    return (
      <Box textAlign='center'>
       <Button
                color={'primary'}
                variant={'contained'}
                size={'large'}
                style={{margin: '0 auto', display: "flex"}}
                onClick={() => { 
                if(show==false) setShow(true)
                if(show==true) setShow(false)
                setCount(count + 1)}
        }
              >
                Click to  {show==true? 'Close 3D App' : ' view Payoff surface in 3D ' }
              </Button>
      { show==true && 
         <GeogebraDraw 
              jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/options_intro/journey_treasure.json"
              ggbParameters={ggbParameters}
              appId="appId2"
              email="pavanmirla@gmail.com"
              data={[
         
              ]}
            /> 
      }
      </Box>
    )
  }



  const Player = () => {
    const ref = React.createRef()
    return (
      <div>

        <div style={{position:"fixed", top: "55%", left: "1%", width:"500px", height: 500}}>
              {/* <h1>Buy</h1> */}
              <Link href="/signin"  color="primary">
                  {"Google signin"}
              </Link>
              <div >
              <img style={{maxWidth: "100%", maxHeight: "100%" , display: "block" }} src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/buy_pic.png"></img>
              </div>

        </div>

      </div>
    )
  }

  

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Switch>
    
         {
            mathTopicsJSON.map(({id, Component}) => (
              <Route key={id} path={"/"+id}>
              {/* {!paymentFlag ? (
                  <Link href="/purchase"  color="primary">
                  {"Google signin and then Checkout"}
              </Link>
             ) : (
              <Component/>
            )} */}
            { <Component/>}

              </Route>


                
              
            ))
          }  
        <Route exact path="/">
        <AppHeader handleDrawerToggle={handleDrawerClick}/>
        {/* <div style={{marginTop: "100px"}}></div> */}
          {/* <SubHeading title={"Welcome to Interactive Learning: A Modern Approach"}/> */}
            {blogs_chain.length > 0 &&
          <>
      
      {currentUser==null && (
              <div style =  {{
                zIndex: 10000000000,
                position: "fixed",
                bottom: "5%",
                left: "0%",
                padding: "18px 36px",
                width: "300px",
                height: "50px",
                // border: "3px solid #73AD21",
                // backgroundColor: "#373737",
                cursor: "pointer",
                fontFamily: "Arial",
                // border: "2px solid gray"
            }}>
          
              <Button  style={{
                  // borderRadius: 35,
                  backgroundColor: "#f2c744",
                  padding: "18px 36px",
                  fontSize: "12px",
                  color: "black"
              }}
              onClick={(event) => {
                localStorage.setItem("clicked_buy", true)
                window.location.href='/signin'
               }}
              variant="contained" color="info">
                {"BUY"}
               <br/>
           
              </Button>
     
            </div>

      )
     }
      

      {currentUser!=null && (
        <div>     
          {!paymentFlag ? ( <>
              <div style =  {{
                   zIndex: 10000000000,
                   position: "fixed",
                   top: "30%",
                   left: "40%",
                   padding: "18px 36px",
                   width: "300px",
                   height: "410px",
                   // border: "3px solid #73AD21",
                   backgroundColor: "#373737",
                   cursor: "pointer",
                   fontFamily: "Arial",
                   border: "2px solid gray"
              }}
                //  onClick={() => createCheckoutSession(currentUser.uid)}
                 >
                            
                <Typography variant="body1" gutterBottom style={{color: 'gray'}}>
                    Hello, ✋
                    {currentUser.email}
                    <br/>
          
                    <br/>
                </Typography>
            

                <Typography variant="body1" gutterBottom style={{color: 'white'}}>
                5 years license for $300  
                <br/>
                </Typography>
                <Typography variant="body1" gutterBottom style={{color: 'white'}}>
                ( $60 per year. $5 per month )
                <br/>
                </Typography>

              
                <Typography variant="body1" gutterBottom style={{color: 'gray'}}>
                Includes unlimited simulations
                <br/>
                </Typography>
                <br/>
                <Button  style={{
                      // borderRadius: 35,
                      backgroundColor: "#f2c744",
                      padding: "18px 36px",
                      fontSize: "12px",
                      color: "black"
                  }}
                  onClick={() => () => createCheckoutSession(currentUser.uid)} variant="contained" color="info">
                    {"Make a one time payment "}
                  </Button>
                <br/>
                <br/>

              
                <Typography variant="body1" gutterBottom style={{color: 'white'}}>
                After you click purchase button, wait 10 seconds ⏳
                <br/>
   
                </Typography>

    

               <br/>
                <Button  style={{
                      // borderRadius: 35,
                      backgroundColor: "#f2c744",
                      padding: "5px 6px",
                      fontSize: "12px",
                      color: "black",
                      width: "80%"
                  }}
                  onClick={() => window.location = 'mailto:office@21ifm.com?subject=I would like to learn a bit about financing options!&body=Thanks!'} variant="contained" color="info">
                    {/* {"Financing options \n office@21ifm.com"} */}
                    <>
                    <p>Click for Financing options</p>
                    <p>Other queries: office@21ifm.com</p>
                    </>
       
                  </Button>
                <br/>

         
               </div>
             </>
      
          ) : (
            <>
            <div style =  {{
              zIndex: 10000000000,
              position: "fixed",
              display: "hide",
              bottom: 0,
              right: 0,
              width: "200px",
              height: "60px",
              // border: "3px solid #73AD21",
              backgroundColor: "#fecf02",
              cursor: "pointer"
          }}>
             {currentUser.email}
            🍪 Premium customer!
            </div>
          </>
          )}
        </div>
      )}


      <br/>
    

      {/* <AreaMap1/>
      <AreaMap2/> */}
       {/* <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/video_for_linkedin.mp4"}/> */}
      



{/* 
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="20vh"
    >
          <Typography variant={'h4'} fontWeight={700} gutterBottom color={"#90caf9"}>
                        INTERCATIVE FINANCIAL MATHEMATICS  FOR FINANCE PROFESSIONALS AND NEW COMERS
                        </Typography>
  </Box> */}


    {/* <Grid container spacing={10}
              justify="center" 
              direction="row"
              alignItems="center"
              justifyContent="center"
      >
      <Grid item xs={12} md={6} justifyContent="center">
          <WhoAmI  title="Options are new to me" 
                            description= "My background isn't in finance or math. With guidance, I'd like to start at the very beginning. I would like to learn about advanced strategies step by step, gradually" 
                            profile="beginner" 
                            image="https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif"
                            handleClick = {handleIsbeginnerClick}
                    />
      </Grid>
      <Grid item xs={12} md={6} justifyContent="center">
          <WhoAmI  title="I'm familiar with options" description= "I need to brush up on my understanding of options pricing, advanced strategies, and the interrelationships between Greeks at the strategy level." profile="notbeginner"
                                image="https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif"
                                handleClick = {handleIsbeginnerClick}
                        />
      </Grid>
      
      </Grid>  */}




            {/* <MyDynamicButton /> */}


           {/* <Box sx={{ 
                  display: "flex" ,
                  alignItems: "center",
                  justifyContent: 'center',
                  width: "100%",
                  backgroundColor: "#1e1e1e"
                  // minHeight: "2000px"
                }}>
           <iframe src="http://docs.google.com/gview?url=https://storage.googleapis.com/chapters-ifm/testfolder/2022/learning_plan.pdf&embedded=true" width="100%" height="1000px"  style={{width:"718px", height:"1700px;"}} frameborder="0"></iframe>
           </Box> */}

          {/* { userProfile == "beginner" && <> 
          <br/>
          <br/>
          <br/>
          <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/journey_beginner_fixed.mp4"}/>
          <br/>
          </>
          }

          {
          userProfile=="notbeginner" && <>
          <br/>
            <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/intro_advanced.mp4"}/>
          <br/>
            </>
          } */}
{/*           
          <button onClick={() => createCheckoutSession(user.uid)}>
            Upgrade to premium!
          </button>
           */}






          {/* <CardsForBlogs in_json={blogs_chain[0].blogs} title="Featured interactive options explainers for finance professionals" 
           description = "Long description"
           image = "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/GENERAL/Merton-1.png"/>

           <CardsForBlogs in_json={blogs_chain[1].blogs} title="Popular interactives for newcomers to options" description = "Long description"
           image = "none" /> */}
          {!paymentFlag && (
            <>
                  <CardsForBlogs in_json={[]} title="AN INTERACTIVE, SIMULATIONS BASED  E-LEARNING PLATFORM TO MASTER OPTIONS." description = "Long description"
                    image = "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/video_linkedin_3.mp4" />
        


                <Box sx={{ 
                        display: "flex" ,
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: 'center'
                        }}>
                    
                      <ButtonMailto label="setup demo" mailto="mailto:office@21ifm.com?subject=I would like to setup a demo!&body=Thanks!" />
                </Box>
            </>
            )}



<CardsForBlogs in_json={[]} title="WHY LEARN OPTIONS?" description = "Long description"
                    image = "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/audience.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/merton_intro.jpg,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/agri_options.jpg,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/commodity_options.jpg,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/fin_options.jpg" />


           <CardsForBlogs in_json={blogs_chain[2].blogs} title="CALL OPTIONS INTRINSIC VALUE, EXTRINSIC VALUE, MONEYNESS, EXERCISE, ASSIGNMENT AND PRICING " 
           description = "Long description"
           image = "none"/>

          <CardsForBlogs in_json={blogs_chain[16].blogs} title="BUY AND SELL CALLS TO CREATE DEFINED RISK CALL SPREADS" description = "Long descption"
                    image = "none" />








          <CardsForBlogs in_json={blogs_chain[4].blogs} title="MATHEMATICS OF CALL OPTION PRICING" description = "Long description"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/sinclair_quote.jpg,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/myron_quote.jpg,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/warren_quote.jpg" />

{/* https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif */}


{/* https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif */}

         <CardsForBlogs in_json={blogs_chain[5].blogs} title="PUT OPTIONS INTRINSIC VALUE, EXTRINSIC VALUE, MONEYNESS, EXERCISE, ASSIGNMENT AND PRICING " description = "Long descption"
           image = "none" />

{/* https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/warren_puts.gif */}


<CardsForBlogs in_json={blogs_chain[9].blogs} title="BUY AND SELL PUTS TO CREATE DEFINED RISK SPREADS" description = "Long descption"
                    image = "none" />

{/* https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/GREEKS/possibilities.png */}

    
         <CardsForBlogs in_json={blogs_chain[6].blogs} title="SYNTHETIC STOCK AND OPTIONS. PUT-CALL PARITY. ARBITRAGE" description = "Long descption" 
          image = "none"/>


          {/* <CardsForBlogs in_json={blogs_chain[7].blogs} title="CALL OPTIONS GREEKS. CALL PRICE SENSITIVITY TO STOCK PRICE, VOLATILITY, TIME TO MATURITY" description = "Long descption" 
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/GENERAL/greeks.gif" />
   */}


  
 
         { userProfile == "notbeginner" && <> 
         
      
          <CardsForBlogs in_json={blogs_chain[8].blogs} title="HISTORICAL AND IMPLIED VOLATILITY " description = "Long description"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/engle_2.png" />

   
          <CardsForBlogs in_json={blogs_chain[10].blogs} title="BUYING AND SELLING CALLS ALONGSIDE PUTS. VOLATILITY STRATEGIES " description = "Long description"
                    image = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/martha_stewart.png" />
                    


          <CardsForBlogs in_json={blogs_chain[12].blogs} title="TIME SPREADS. DIFFERENT EXPIRY DATES AND DIFFERENT STRIKE PRICES" description = "Long descption"
                    image = "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/GENERAL/spreads_comparison.png" />      

          <CardsForBlogs in_json={blogs_chain[13].blogs} title="HEDGING AND INCOME GENERATION WHILE HOLDING UNDERLYING" description = "Long descption"
                    image = "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/GENERAL/cuban_anim.gif" />


         </>

         }
          

          {/* <CardsForBlogs in_json={blogs_chain[14].blogs} title="RISK MANAGEMENT" description = "Long description"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/allianz_leeson.gif" /> */}
     

         <CardsForBlogs in_json={blogs_chain[3].blogs} title="MATHEMATICS AND STATISTICS" description = "Long description"
           image = "https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif" />

        
         <CardsForBlogs in_json={blogs_chain[17].blogs} title="EMPLOYEE STOCK OPTIONS AND TAXES" description = "Long descption"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/esop/bb_fixed.png"  />

    <CardsForBlogs in_json={blogs_chain[17].blogs} title="UPCOMING CHAPTERS IN NOV 2022" description = "Long descption"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/50.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/51.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/52.png"  />



        <CardsForBlogs in_json={blogs_chain[15].blogs} title="WEEKLY MARKET NEWS, OPPORTUNITIES AND CONCEPT REVISIONS" description = "Long descption"
          image = "none" />
          
          {!paymentFlag && (
          <CardsForBlogs in_json={[]} title="BUY" description = "Long descption"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/pricing_3.png"  />
          )}



          <Box sx={{ 
              display: "flex" ,
              alignItems: "center",
              justifyContent: 'center'
            }}>
              <ButtonMailto label="setup demo" mailto="mailto:office@21ifm.com?subject=I would like to setup a demo!&body=Thanks!" />
      </Box>




©
{/* <div style={{ width: '100%' }}>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} sx= {{alignItems: 'center'}}>
        <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', p: 2, alignItems: 'center' }}>
          <Demo title ="When to sell options?" description={"Options expert Larry McMillan explaining selling options is profitable only when current market volatility is high"} video = {"https://www.youtube.com/embed/88eG8-3qpds"}/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            bgcolor: 'secondary.main',
            color: 'secondary.contrastText',
            p: 2,
          }}
        >
          <Demo title ="Lizard" description={"This is my description"} video = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/optionsIntroAd.mp4"/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ bgcolor: 'error.main', color: 'error.contrastText', p: 2 }}>
         <Demo title ="Lizard" description={"This is my description"} video = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/optionsIntroAd.mp4"/>
        </Box>
      </Grid>
     
    </Grid>
  </div> */}

          {/* <SubHeading title={"Synthetics"}/>

          <SubHeading title={"Advanced Strategies"}/>

          <SubHeading title={"Risk Management"}/>

          <SubHeading title={"Black Scholes Options pricing"}/> */}



          {/* <CardsForBlogs in_json={blogs_chain[3].blogs} /> */}
          </>
         }
        </Route>
   

        <Route path="/taxes">
          <h2>Taxes</h2>
        </Route>


        <Route path="/loans">
          <h2>Loans</h2>
        </Route>


        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/register">
          <SignUp />
        </Route>

        <Route path="/signinLink">
          <SignInMagicLink />
        </Route>


        <Route path="/signinemail">
          <SignInEmail />
        </Route>



        <Route path="/payment">
          <Payment />
        </Route>

        <Route path="/wait">
          <MagicLinkWait />
        </Route>

        <Route path="/forgotPassword">
          <ForgotPassword />
        </Route>

        <Route path="/signin">
          <SignIn />
        </Route>
 
        <Route path="/purchase">
          <SignInPlusPurchase/>
        </Route>
 

 

        <Route path="/__/auth/">
          <SignIn />
        </Route>

        </Switch>
      </Router>
    </ThemeProvider>
  );
}

function Home() {


  return (
    <div >
      {/* <AppHeader handleDrawerToggle={handleDrawerClick}/> */}
      <main >
     
      </main>
    </div>
  );
}


