import React from "react";

import * as posts from "./posts";

export default () => [
  {
    name: "OPTIONS VOLATILITY",
    id: "options_volatility",
    Component: posts.Volatility_Intro,
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/implied_vol.mp4",
    image:
    "",
    description:
      "Using exponentially weighted moving average method for Volatility smoothing ",
    active: 1,
    category: "featured_pro",
    level: "P"
  },
  {
    name: "SHORT/CREDIT IRON CONDORS",
    id: "short_condor_full",
    Component: posts.Volatility_Iron_Condor,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/iron_condor_home.mp4",
    description:
      "Similar to Strangles. Collect premium.Limiting risk. capping profits",
    active: 0,
    category: "featured_pro",
    level: "P"
  },
  {
    name: "SHORT  CONDORS",
    id: "short_condor",
    Component: posts.Condor_with_Puts,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/short_condors.mp4",
    description:
      "Condor spreads are made up of the same class of options, either all call options or all put options. Iron Condors are slighlty different",
    active: 1,
    category: "featured_pro",
    level: "P"
  },
  {
    name: "Margin for Call Seller",
    id: "margin_call_seller",
    Component: posts.Margin_Call_Options,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/margin_home.mp4",
    description:
      "Maintenance margin, Initial margin requirements for basic to advanced strategies",
    active: 1,
    category: "featured_pro",
    level: "P"
  },
  {
    name: "PUT DELTA",
    id: "put_delta",
    Component: posts.LPDelta2D,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/put_delta_ls.png",
    description:
      "Put premium change based on stock price. Delta Neutral portfolio. Delta for long/short put",
    active: 1,
    category: "featured_beginner",
    level: "P"
  },
  {
    name: "CALL ASSIGNMENT AND EXERCISE",
    id: "call_buyer_seller",
    Component: posts.Calls_basic_buyer_seller,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/desktop_call_buyer-seller.png",
    description:
      "Payoffs for buyers and sellers of a call option. Right and Obligations. Scenarios for exercise",
    active: 1,
    category: "featured_beginner",
    level: "P"
  },
  {
  
    name: "THETA SURFACE FOR LONG/SHORT CALL.",
    id: "theta_3d",
    Component: posts.LCTheta3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/heta_home.mp4",
    description:
      "Resembles a Trampoline for long Call.Sensitivity to implied volatilities, time, and rates",
    active: 1,
    category: "featured_beginner",
    level: "P"
  },

  {
    name: "BEGINNERS GUIDE TO BUYING AND SELLING CALL OPTIONS",
    id: "call_intro",
    Component: posts.IntroCall,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/steps.jpg",
    video :"",
    description:
      "What are Call Options? Why use Call Options?  Parity and payoff graphs. Seller and Buyer.",
    active: 1,
    category: "calls",
    level: "P"
  },
  {
    name: "OPTIONS CHAIN",
    id: "options_chain",
    Component: posts.OptionsChain,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/options_chain.png",
      video: "",
    description:
    "Option chain structure. Bid and Asks for calls and Puts. Market value, noneyness, greeks, open interest,implied volatility ",
    active: 0,
    category: "calls",
    level: "P"
  },

  {
    name: "LONG CALL SENSITIVITY TO STOCK, VOLATILITY AND TIME",
    id: "call_greeks_long",
    Component: posts.Calls_Greeks_Long,
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/call_thumbnail_2.mp4",
    image:
    "",
    description:
      "Long call option Greeks - Delta, Gamma, Vega, Theta ",
    active: 1,
    category: "calls",
    level: "P"
  },
  {
    name: "SHORT CALL SENSITIVITY TO STOCK, VOLATILITY AND TIME",
    id: "call_greeks_short",
    Component: posts.Calls_Greeks_Short,
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_thumbnail.mp4",
    image:
    "",
    description:
      "Short call option Greeks - Delta, Gamma, Vega, Theta ",
    active: 1,
    category: "calls",
    level: "P"
  },
  {
    name: "INITIAL AND MAINTENANCE MARGIN REQUIREMENTS FOR A SOLD CALL.",
    id: "margin_call_seller",
    Component: posts.Margin_Call_Options,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/margin_call_2.jpg",
    video: "",
    description:
      "Interactive exploration and simulation of impact of volatility, time and stock price on margin requirements ",
    active: 1,
    category: "calls",
    level: "P"
  },
  {
    name: "TEST YOUR KNOWLEDGE OF BUYING AND SELLING CALL OPTIONS",
    id: "greeks_questions",
    Component: posts.GreeksProblem,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/quiz.jpg",
    video: "",
    description:
      "Right to buy an asset at a fixed price. But not obligated.",
    active: 1,
    category: "calls",
    level: "P"
  },
  {
    name: "INFLUENCE OF VOLATILITY, TIME, STRIKES ON DELTA IN 3D",
    id: "delta_3d",
    Component: posts.LCDelta3DSensitivity,
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/basics/delta_3d.mp4",
    image:
    "",
    description:
      "Delta in relation to volatility, moneyness and time in 3D",
    active: 1,
    category: "calls",
    level: "P"
  },
  {
    name: "EXPLORE GAMMA - THE RATE OF CHANGE OF DELTA IN MULTIPLE DIMENSIONS ",
    id: "gamma_3d",
    Component: posts.LCGamma3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/gamma_3d/thumbnail_gamma.mp4",
    description:
      "Rate of change of delta. Gamma in relation to price changes, volatility, and time",
    active: 1,
    category: "calls",
    level: "P"
  }, 
  {
  
    name: "THETA - MEASURE OF OPTIONS's SENSITIVITY TO TIME SHOWN IN 3D",
    id: "theta_3d",
    Component: posts.LCTheta3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/theta_3d/thumbnail_theta.mp4",
    description:
      "Resembles a Trampoline for long Call.Sensitivity to implied volatility, time, and rates",
    active: 1,
    category: "calls",
    level: "P"
  },
  {
    name: "PYTHON NOTEBOOKS",
    id: "python_notebooks",
    Component: posts.PythonNotebooks,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/python_thumbnail.jpg",
    video: "",
    description:
      "Learn how to analyze Options data with Python. Get tutorials with videos.",
    active: 1,
    category: "calls",
    level: "P"
  },

  {
    name: "LINEAR FUNCTION",
    id: "linear_function",
    Component: posts.LinearFunction,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/linear_func.mp4",
    description:
      "Fundamentals of gradients and slope to calculate payoffs and changes in option premium",
    active: 0,
    category: "math",
    level: "P"
  },
  {
    name: "QUADRATIC FUNCTION",
    id: "quadratic_function",
    Component: posts.QuadraticFunction,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/quad_func.mp4",
    description:
      "Estimate convexity, concavity to analyze risks of changing volatility, time and stock price",
    active: 0,
    category: "math",
    level: "P"
  },
  {
    name: "CONVEXITY AND CONCAVITY",
    id: "convexity",
    Component: posts.Convexity,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/convexity/convexity_thumbnail.mp4",
    description:
      "Determine whether a function is convex or concave. A secant line can be used to approximate a tangent. Illustration of Jensen's inequality. ",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "WHY LOG RETURNS",

    id: "log_returns",
    Component: posts.LogReturns,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/log_returns/log_ret_thumbnail_video.mp4",
    description:
      "Why are financial assets commonly modeled with lognormal distributions? The advantages of log normal distributions",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "CONTINUOUS COMPOUNDING ",

    id: "compounding",
    Component: posts.Compounding,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/compounding/euler_video.mp4",
    description:
      "The logic behind continuous compounding. An illustration of exponential functions. Applications of growth rates in finance",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "LOGNORMAL DISTRIBUTION",

    id: "lognormal_normal",
    Component: posts.Normal_LogNormal_relationship,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/log_normal.mp4",
    description:
      "Lognormal distribution for financial assets. The relationship with normal distributions",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "EXPONENTIAL FUNCTIONS",
    id: "exponential_function",
    Component: posts.Exponential_Logarithm_basics,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/exponential_functions.mp4",
    description:
      "Natural log, Exponential and linear functions. Special number e",
    active: 0,
    category: "math",
    level: "P"
  },
  {
    name: "BINOMIAL DISTRIBUTION",
    id: "binomial_dist",
    Component: posts.GaltonBoard_Binomial,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/binomial_func.mp4",
    description:
      "Applicable in pricing options with a multistep Binomial Model",
    active: 0,
    category: "math",
    level: "P"
  },
  {
    name: "CUMULATIVE DISTRIBUTION ",
    id: "cumulative_dist",
    Component: posts.Cumulative_Dist_Stock,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/density_functions.mp4",
    description:
      "Estimate probability of moneyness using standard normal cumulative distribution",
    active: 0,
    category: "math",
    level: "P"
  },
  {
    name: "TAYLOR SERIES TO APPROXIMATE DELTA AND GAMMA",
    id: "taylor_delta_gamma",
    Component: posts.Taylor_delta_Gamma,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/taylor_delta_gamma/taylor_series.jpg",
    description:
      "Find average Gamma, Gamma PNL for long call. Application of Taylor polynomial to estimate Greeks",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "BEGINNERS GUIDE TO PRICING DERIVATIVES",
    id: "pricing_framework",
    Component: posts.Pricing_Framework,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/steps.jpg",
    video :"",
    description:
      "Risk neutral framework. Log Normal distributions. Stochastic processes. Ito's lemma",
    active: 1,
    category: "pricing",
    level: "P"
  },
  {
    name: "BLACK SCHOLES FRAMEWORK ",
    id: "black_scholes",
    Component: posts.Black_Scholes_Simulation,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/black_scholes/thumb_bs_2.png",
    video :"",
    description:
      "Analysis of all variables and assumptions in Black Scholes pricing",
    active: 1,
    category: "pricing",
    level: "P"
  },

  {
    name: "BLACK SCHOLES SOLUTION ",
    id: "black_scholes_solution",
    Component: posts.Black_Scholes_Density,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/basics/black_scholes_mathias.png",
    video :"",
    description:
      "Black Scholes formula d1, d2, N(d1) and N(d2) explained for calls and puts",
    active: 1,
    category: "pricing",
    level: "P"
  },
  

  {
    name: "BINOMIAL FRAMEWORK",
    id: "binomial_pricing",
    Component: posts.Binomial_one,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/basics/Binomial_matthias.png",
    video :"",
    description:
      "Pricing of call option example with Binomial framework for option pricing",
    active: 0,
    category: "pricing",
    level: "P"
  },
  {
    name: "BUYING AND SELLING PUT OPTIONS",
    id: "put_intro",
    Component: posts.IntroPut,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/steps.jpg",
    video :"",
    description:
      "What are Put Options? Why use Put Options? Parity and payoff graphs. Seller and Buyer. Intrinsic Value at expiry.",
    active: 1,
    category: "puts",
    level: "P"
  },

  {
    name: "LONG PUT SENSITIVITY TO STOCK, VOLATILITY AND TIME. FIRST AND SECOND ORDER GREEKS",
    id: "all_greeks_put_long",
    Component: posts.Puts_Greeks_Long,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_put_long/put_long_thumbnail.png",
    description:
      "Sensitivity of put premium to stock, volatility, time and rates. ",
    active: 1,
    category: "puts",
    level: "P"
  },
  {
    name: "SHORT PUT SENSITIVITY TO STOCK, VOLATILITY AND TIME. FIRST AND SECOND ORDER GREEKS",
    id: "all_greeks_put_short",
    Component: posts.Puts_Greeks_Short,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_put_short/short_put_thumbnail.png",
    description:
      "Sensitivity of short put premium to stock, volatility, time and rates ",
    active: 1,
    category: "puts",
    level: "P"
  },
  {
    name: "Margin for Put seller",
    id: "margin_Put_seller",
    Component: posts.Margin_Put_Options,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/margin_call_2.jpg",
    video: "",
    description:
      "Maintenance margin, Initial margin requirements for basic to advanced strategies",
    active: 1,
    category: "puts",
    level: "P"
  },

  {
    name: "QQQ DEEP OTM PUTS CASE STUDY",
    id: "casestudy_qqq",
    Component: posts.Puts_CaseStudy,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_put/qqq_loss.png",
    description:
      "Learn about importance of risk reward assesment. Calculation of probability of profit adn impact of time and volatility on long put",
    active: 1,
    category: "puts",
    level: "P"
  },


  {
    name: "PUT OPTION EXTRINSIC VALUE DRIVERS",
    id: "puts_ev",
    Component: posts.Puts_ev,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/puts_long.png",
      video: "",
    description:
    "Right to sell an asset at a fixed price. Similar to insurance. Price, Volatility and time are simulated ",
    active: 0,
    category: "puts",
    level: "P"
  },

 
  {
    name: "PUT EXERCISE",
    id: "puts_buyer_seller_block",
    Component: posts.Puts_buyer_seller,
    image:
      "",
    video : "https://storage.googleapis.com/chapters-ifm/testfolder/videos/PUTS_CASH_SECURED.mp4",
    description:
      "Payoffs for buyers and sellers of a put option",
    active: 0,
    category: "puts",
    level: "P"
  }, 

  {
    name: "PUT ASSIGNMENT AND EXERCISE",
    id: "puts_buyer_seller",
    Component: posts.Puts_Seller_Buyer,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/2022/put_seller_buyer.png",
    description:
      "Payoffs for buyers and sellers of a put option.Right and Obligations. Scenarios for exercise",
    active: 0,
    category: "puts",
    level: "P"
  },
  {
    name: "PROTECTIVE PUT ",
    id: "protective_put",
    Component: posts.Puts_ptotective_put,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/2022/put_seller_buyer.png",
    description:
      "Payoffs for buyers and sellers of a put option.Right and Obligations. Scenarios for exercise",
    active: 0,
    category: "puts",
    level: "P"
  },
 

  {
  name: "SYNTHETICS/ PUT CALL PARITY",
  id: "put_call_parity",
  Component: posts.Put_Call_Parity,
  image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/merton_thumbnail.jpg",
    video: "",
  description:
  "Replicate asset features by combining multiple options or underlying",
  active: 1,
  category: "synthetics",
  level: "P"
},
{
  name: "PUT CALL PARITY ANOMALY/ ARBITRAGE",
  id: "put_call_parity_arbitrage",
  Component: posts.Put_Call_Parity_Arbitrage,
  image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/arbitrage.jpg",
    video: "",
  description:
  "This is a way to make money without investing when put call parity does not hold. Ever heard of free lunch?",
  active: 1,
  category: "synthetics",
  level: "P"
},
{
  name: "CONVERSIONS",
  id: "conversions",
  Component: posts.Conversions,
  image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/conversion_thumbnail.jpg",
    video: "",
  description:
  "An arbitrage strategy for riskless profit when options are overpriced relative to the underlying stock.",
  active: 1,
  category: "synthetics",
  level: "P"
},

 


  {
    name: "CALL DELTA",
    id: "call_delta",
    Component: posts.LCDelta2D,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/call_delta_ls.png",
    description:
      "Call premium change based on stock price. Delta as hedge ratio, probability of ITM",
    active: 1,
    category: "greeks_call",
    level: "P"
  },
 

  {
    name: "PUT DELTA",
    id: "put_delta",
    Component: posts.LPDelta2D,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/put_delta_ls.png",
    description:
      "Put premium change based on stock price. Delta Neutral portfolio. Delta for long/short put",
    active: 1,
    category: "greeks_call",
    level: "P"
  },
 
   
  {
    name: "GAMMA SQEEZE",
    id: "gamma_squeeze",
    Component: posts.GammaSqueeze,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/gamma_sqeeze.png",
    video: "",
    description:
      "Gamma explosion could be profitable. Conditions required for big Gamma changes",
    active: 1,
    category: "greeks_call",
    level: "P"
  },
  {
    name: "CALL THETA",
    id: "theta_2d",
    Component: posts.LCTheta2D,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/desktop_theta.png",
   video : "",
    description:
      "Call premium decays over time. Theta in relation to volatility and stock",
    active: 1,
    category: "greeks_call",
    level: "P"
  }, 

  {
  
    name: "CALL VEGA",
    id: "calls_vega",
    Component: posts.LCVega2D,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/vega.png",
   video : "",
    description:
      "Changes in call premium based on volatility",
    active: 1,
    category: "greeks_call",
    level: "P"
  },
  


  {
    name: "ALL GREEKS FOR LONG STRADDLE",
    id: "all_greeks_straddle",
    Component: posts.Straddle_Greeks,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/deskitop_call.png",
    description:
      "Sensitivity of call price to defined parameters",
    active: 0,
    category: "greeks_call",
    level: "P"
  },

  {
    name: "BEGINNERS GUIDE TO MODEL VOLATILITY AND VOL FORECASTING",
    id: "options_volatility",
    Component: posts.Volatility_Intro,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/steps.jpg",
    description:
      "Autoregressive techniques. ARIMA models. ARCH framework. maximum likelihood estimation",
    active: 0,
    category: "volatility_intro",
    level: "P"
  },

  {
    name: "REALIZED AND IMPLIED VOLATILITY. IV TERM STRUCTURE, SKEW",
    id: "options_volatility",
    Component: posts.Volatility_Intro,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/ewma/thumb4.png",
    description:
      "Illustration of volatility as standard deviation. Implied vol calibration technique",
    active: 1,
    category: "volatility_intro",
    level: "P"
  },

  {
    name: "VOLATILITY FORECASTING - EXPONENTIALLY WEIGHTED MOVING AVERAGE (EWMA)",
    id: "ewma",
    Component: posts.EWMA,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/ewma/thumb2.png",
    description:
      "Using exponentially weighted moving average method for Volatility smoothing ",
    active: 1,
    category: "volatility_intro",
    level: "P"
  },



  {
    name: "BULLISH SPREAD WITH PUTS",
    id: "credit_puts_spread",
    Component: posts.Bullish_Put_Spread,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/bull_put_spread.png",
      video: "",
    description:
    "Sell a expensive ITM Put to collect premium like an insurance company. Reduce cost, Reduce risk by buying a cheaper Put ",
    active: 1,
    category: "put_spreads",
    level: "P"
  },
  {
    name: "BEARISH SPREAD WITH PUTS",
    id: "debit_puts_spread",
    Component: posts.Bearish_Put_Spread,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/bearish_PS.png",
      video: "",
    description:
    "Net Debit. Long ITM Put. Sell OTM Put. Learn about entering trade, calculating profits and losses and volatility impact on this bearish outlook trade   ",
    active: 1,
    category: "put_spreads",
    level: "P"
  },

  {
    name: "EXPECTING DRASTIC DOWNTURN.PUT RATIO SPREAD.",
    id: "bearish_ratio_spread_puts",
    Component: posts.Bearish_Put_Ratio,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/put_ratio_spread.png",
    video: "",
    description:
      "Bearish, Expecting a breakdown in stock. Reserved for experienced speculators",
    active: 1,
    category: "put_spreads",
    level: "P"
  },
  {
    name: "NEUTRAL. SLIGHTLY BEARISH.PUT RATIO SPREAD. ",
    id: "neutral_ratio_spread_puts",
    Component: posts.Neutral_Put_Ratio,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/neutral_put_ratio.png",
    video: "",
    description:
      "Bearish, Expecting a breakdown in stock. Reserved for experienced speculators",
    active: 1,
    category: "put_spreads",
    level: "P"
  },

  {
    name: "BEGINNERS GUIDE TO VOLATILITY STRATEGIES",
    id: "beginner_vol_strategies",
    Component: posts.Beginner_Vol_Strategies,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/steps.jpg",
    video: "",
    description:
      "Introduction to setting up a long Straddle (a debit trade)",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },


  {
    name: "BINARY EVENT. DEBIT STRADDLE",
    id: "straddle_debit",
    Component: posts.Straddle_Debit,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/long_straddle_thumbnail.jpg",
    video: "",
    description:
      "Introduction to setting up a long Straddle (a debit trade)",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },

  {
    name: "INSURANCE SELLER. CREDIT STRADDLE",
    id: "straddle_credit",
    Component: posts.Volatility_Straddle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/credit_straddle_thumbnail.jpg",
    video: "",
    description:
      "Volatility high environment to enter. Expecting a calm stock price action. Sell high, buy lower",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },

  {
    name: "CREDIT STRANGLE",
    id: "strangle_credit",
    Component: posts.Volatility_Strangle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/credit_strangle.jpg",
    video: "",
    description:
      "Volatility high environment to enter. Expecting a calm stock price action. Risk of big stock move",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
 

  {
    name: "BUTTERFLY SPREAD",
    id: "butterfly_spread",
    Component: posts.Butterfly_Spread,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/test.mov",
    description:
      "Long Butterfly (favoring neutral price). Short Butterfly (favoring price movement in either direction). Setup with either all calls/ all puts",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "IRON BUTTERFLY",
    id: "iron_butterfly",
    Component: posts.Iron_Butterfly,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/iron_butterly_thumb.jpg",
    video: "",
    description:
      "Short Iron Butterfly ( favoring neutral price).Long Iron Butterfly ( favoring large price movement in either direction). Iron butterfly mixes calls and puts",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "DEBIT AND CREDIT CONDOR SPREAD",
    id: "long_short_condor",
    Component: posts.Condor_with_Puts,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/short_condors.mp4",
    description:
      "The goal of the iron condor is to have both short options expire worth-less",
    active: 0,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "SHORT/CREDIT IRON CONDORS",
    id: "short_condor",
    Component: posts.Volatility_Condor,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/iron_condor_home.mp4",
    description:
      "The goal of the iron condor is to have both short options expire worth- less.Similar to Strangles. Collect premium.Limiting risk. capping profits",
    active: 0,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "SHORT/CREDIT IRON CONDORS",
    id: "short_condor_full",
    Component: posts.Volatility_Iron_Condor,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/iron_condor_thumb.jpg",
    video: "",
    description:
    "The goal of the iron condor is to have both short options expire worth- less.Similar to Strangles. Collect premium.Limiting risk. capping profits",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "SHORT STRADDLE. EXPECTING MARKET TO STAGNATE",
    id: "short_straddle",
    Component: posts.Neutral_Straddle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/short_straddle.png",
    video: "",
    description:
      "Expecting minimal price movement.The maximum profit is the amount of premium collected by writing the options.",
    active: 0,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "EXPECTING DRASTIC DOWNTURN.PUT RATIO SPREAD.",
    id: "bearish_ratio_spread_puts",
    Component: posts.Bearish_Put_Ratio,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/put_ratio_spread.png",
    video: "",
    description:
      "Bearish, Expecting a breakdown in stock. Reserved for experienced speculators",
    active: 1,
    category: "ratio_spreads",
    level: "P"
  },
  {
    name: "NEUTRAL. SLIGHTLY BEARISH.PUT RATIO SPREAD. ",
    id: "neutral_ratio_spread_puts",
    Component: posts.Neutral_Put_Ratio,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/neutral_put_ratio.png",
    video: "",
    description:
      "Bearish, Expecting a breakdown in stock. Reserved for experienced speculators",
    active: 1,
    category: "ratio_spreads",
    level: "P"
  },
  {
    name: "NEUTRAL. CALENDAR SPREADS",
    id: "put_long_calendar_spread",
    Component: posts.Neutral_Calendar_Spread,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/debit_calendar_home.png",
    video: "",
    description:
      "A neutral to mildly bearish/bullish strategy with two calls/puts of the same strike, but different expiry dates.",
    active: 0,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "GENTLE BEGINNERS GUIDE TO CALENDAR AND DIAGONAL SPREADS",
    id: "calendar_beginner",
    Component: posts.Calendar_Beginner,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/steps.jpg",
    video: "",
    description:
      "In a basic calendar spread, you buy a distant month option and sell a closer month option.",
    active: 1,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "NEUTRAL TO SLIGHTLY BEARISH FORECAST. LONG CALENDAR. SELL NEAR-TERM. BUY FAR-TERM",
    id: "neutral_calendar_spread",
    Component: posts.Neutral_Calendar_Spread,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/neutral_calendar.png",
    video: "",
    description:
      "A neutral to mildly bearish strategy. Same strike. Different expiration",
    active: 1,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "BIG MOVE FORECAST. CALENDAR. BUY NEAR-TERM. SELL FAR-TERM",
    id: "short_calendar_spread",
    Component: posts.Short_Put_Calendar,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/directional_puts.png",
    video: "",
    description:
      "The forecast is for a “big stock price change. Up/Down direction",
    active: 1,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "DIRECTIONAL BETS WITH DIAGONAL SPREADS",
    id: "short_diagonal_spread",
    Component: posts.Diagonal_Spread_Credit_Puts,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/calendar_intro_page.png",
    video: "",
    description:
      "Diagonal spreads have different strike prices and different expiration dates. ",
    active: 0,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "BULLISH DIAGONAL SPREAD WITH PUTS",
    id: "diagonal_puts_bullish",
    Component: posts.Diagonal_Spread_Credit_Puts,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/bullish_diagonal_with_puts.png",
    video: "",
    description:
      "Diagonal spreads have different strike prices and different expiration dates. ",
    active: 1,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "BULLISH DIAGONAL SPREAD WITH CALLS",
    id: "diagonal_calls_bullish",
    Component: posts.Diagonal_Spread_Debit_Calls_Bullish,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/bullish_call_diagonal.png",
    video: "",
    description:
      "Diagonal spreads have different strike prices and different expiration dates. ",
    active: 0,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "BEARISH DIAGONAL SPREAD WITH PUTS",
    id: "diagonal_puts_bearish",
    Component: posts.Diagonal_Spread_Debit_Puts,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/bearish_diagonal.png",
    video: "",
    description:
      "Debit/Long Diagonal spread. Buy higher strike, far-term put. Sell lower strike near-term put ",
    active: 1,
    category: "strategy_time",
    level: "P"
  },


  {
    name: "STATIC HEDGE WITH COVERED CALL",
    id: "covered_call",
    Component: posts.CoveredCalls,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/covered_call_tn.jpg",
    video: "",
    description:
      "Generate additional income from existing stock position.",
    active: 1,
    category: "strategy_hedging",
    level: "P"
  },
  {
    name: "COLLAR TO SECURE PORTFOLIO",
    id: "equity_collar",
    Component: posts.Collar,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/equity_collar/cuban_tn.png",
    video: "",
    description:
      "Protect your stock portfolio by limiting the downside, while foregoing some..",
    active: 1,
    category: "strategy_hedging",
    level: "P"
  },
  {
    name: "PUT SPREAD COLLAR",
    id: "synthetic_seagull",
    Component: posts.Seagull_Setup,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/equity_collar/jpm_put_spread_collar.jpg",
    video: "",
    description:
      "Popular JPM hedge trade. Protect partial downside with Put Spread Collar also called Seagull strategy ",
    active: 1,
    category: "strategy_hedging",
    level: "P"
  },
  {
    name: "POOR MAN'S COVERED CALL",
    id: "poor_man_cc",
    Component: posts.PoorMansCoveredCalls,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/calls/covered_call.png",
    video: "",
    description:
      "Similar to Covered call. Synthetic stock position created",
    active: 0,
    category: "strategy_hedging",
    level: "P"
  },

  {
    name: "WHEEL STRATEGY TO COLLECT PREMIUMS",
    id: "wheel_strategy",
    Component: posts.WheelStrategy,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/cash_secured_puts_wheel.png",
    video: "",
    description:
      "Cash Secured Puts and Straddles. Collect premiums",
    active: 1,
    category: "strategy_hedging",
    level: "P"
  },

  {
    name: "PinRisk, Taxes",
    id: "pinrisk",
    Component: posts.Risk_management,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/neiderhoffer.png",
    video: "",
    description:
      "Risk management and lessons from History covering Nick Leesen, Victor Niederhoffer ",
    active: 1,
    category: "risk_management"
  },
  {
    name: "Margin Call on Stocks",
    id: "margincall_stocks",
    Component: posts.Margin_Call_Stocks,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/margin_call_2.jpg",
    video: "",
    description:
      "Maintenance margin, Initial margin requirements for basic to advanced strategies",
    active: 1,
    category: "risk_management",
    level: "P"
  },


  

  {
    name: "Test Quiz",
    id: "test",
    Component: posts.ExperimentLayout,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/markets_pic.png",
    video: "",
    description:
      "Outperforming stocks. High Volume options trades",
    active: 0,
    category: "online",
    level: "P"
  },
  {
    name: "Markets",
    id: "markets",
    Component: posts.MarketData,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/markets_pic.png",
    video: "",
    description:
      "Outperforming stocks. High Volume options trades",
    active: 1,
    category: "online",
    level: "P"
  },

  {
    name: "Seagull",
    id: "call_write_question",
    Component: posts.CallQuestion,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/markets_pic.png",
    video: "",
    description:
      "Outperforming stocks. High Volume options trades",
    active: 0,
    category: "online",
    level: "P"
  },


  {
    name: "BEGINNER GUIDE TO SPREADS",
    id: "spread_beginner",
    Component: posts.Vertical_Beginner,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/steps.jpg",
    description:
      "What are spreads? Why to setup spreads? Risks/ rewards. Margin requirements. Vocabulary",
    active: 1,
    category: "call_spreads",
    level: "P"
  },

  {
    name: "BULLISH SPREAD WITH CALLS",
    id: "bullish_call_spread",
    Component: posts.Bullish_Call_Spread,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bullish_call_spread/bullish_call_spread_thumb.png",
    description:
      "Net Debit. Short OTM. Long ITM Call.Breakeven points. Scenarios for ITM, OTM and ATM options",
    active: 1,
    category: "call_spreads",
    level: "P"
  },
  {
    name: "BEARISH SPREAD WITH CALLS",
    id: "bearish_call_spread",
    Component: posts.Bearish_Call_Spread,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bearish_call_spread/bearish_call_spread_thumb.png",
      video: "",
    description:
    "Net Credit. Short ITM Call. Short OTM Call. Delta, Gamma, Vega, Theta for the spread strategy  ",
    active: 1,
    category: "call_spreads",
    level: "P"
  },
  {
    name: "STOCK REPAIR. BULL CALL RATIO",
    id: "ratio_spread_neutral",
    Component: posts.Bullish_Call_Ratio,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/ratio_spread_neutral/stock_repair_thumbnail.png",
    video: "",
    description:
      "Bullish, Expecting neutral to slightly bullish movement in stock. Gain with Time decay and volatility fall",
    active: 1,
    category: "call_spreads",
    level: "P"
  },
  {
    name: "BULLISH. RATIO BACKSPREAD WITH CALLS",
    id: "ratio_backspread_calls",
    Component: posts.Ratio_Backspread_Calls,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/ratio_backspread_calls/ratio_backspread_1by2.png",
    video: "",
    description:
      "Bullish. Limited downside risk. Expecting big rise in stock volatility. Sell 1 call. Buy 2 long-term calls",
    active: 1,
    category: "call_spreads",
    level: "P"
  },


  {
    name: "DIRECTIONAL BETS WITH DIAGONAL SPREADS",
    id: "short_diagonal_spread",
    Component: posts.Diagonal_Spread_Credit_Puts,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/calendar_intro_page.png",
    video: "",
    description:
      "Diagonal spreads have different strike prices and different expiration dates. ",
    active: 0,
    category: "call_spreads",
    level: "P"
  },

  {
    name: "Employee Stock Options",
    id: "espos",
    Component: posts.ESOPS,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/esop/thumbnnail.jpg",
    video: "",
    description:
      "Ordinary income tax vs Capital gains tax. Short dated vs long dated trades tax implications. Work in progress",
    active: 1,
    category: "esops_taxes",
    level: "P"
  },

 
];
function Default() {
  const text = "Hello World . I am Default APP";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}

function App1() {
  const text = "Hello World . I am inside Pages / App1";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}
