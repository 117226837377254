// import firebase from "firebase";
import firebase from "./firebaseIndex";
import isPaidUserTest from "../stripe/isPaidUserTest";
import usePremiumStatus from "../stripe/usePremiumStatus";

export const authMethods = {
  // firebase helper methods go here...

  signinGoogleAfterRedirect: (user, setErrors, setToken, setCurrentUser, paymentFlag, setPaymentFlag) => {
    const db = firebase.firestore();
    const token =  Object.entries(user)[5][1].b;
    const delay = ms => new Promise(res => setTimeout(res, ms));

    setToken(token);
    // let paymentChecked = isPaidUserTest()

    const checkPremiumStatus = async function () {
      let paymentChecked  = await isPaidUserTest();
      await setPaymentFlag(paymentChecked)
      await localStorage.setItem("paymentFlag", paymentChecked)
      await delay(100);
      // alert('in' +paymentChecked)
    };
    checkPremiumStatus()
 
    // alert(window.localStorage.getItem('paymentFlag'))
    setCurrentUser(user)
    
   //Do we really need this?
    // db.collection("users").where("uid", "==", user.uid)
    //   .get()
    //   .then((querySnapshot) => {
    //      if(querySnapshot.empty) {
    //       db.collection("users").doc(user.uid).set({
    //         uid: user.uid,
    //         email: user.email,
    //         provider: user.providerData[0].providerId,
    //         photoUrl: user.photoURL,
    //       })
    //       .then((docRef) => {
    //           console.log("Document written with ID: ", docRef.id);
    //       })
    //       .catch((error) => {
    //           console.error("Error adding document: ", error);
    //       })
    //      }
    //   })
    //   .catch((error) => {
    //       console.log("Error getting documents: ", error);
    //   });



  },

  signinGoogle: (setErrors, setToken) => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth()
      .signInWithRedirect(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user

        // ...
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });

  },
  signup: (email, password, license, setErrors, setToken) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      //make res asynchonous so that we can make grab the token before saving it.
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b;
        //set token to localStorage
        await localStorage.setItem("token", token);
        setToken(token);
        //grab token from local storage and set to state.
        console.log(res);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });

    const db = firebase.firestore();
    // const userRef = db.collection("users").add({
    //     fullname: 'Not Known. Via Sign in form',
    //     email: email,
    //     verified: true,
    //     license: license
    //   }); 

      // db.collection("users").doc(email).set({
      //   fullname: 'Not Known. Via Sign in form',
      //   email: email,
      //   verified: true,
      //   license: license
      // }).then(() => {
      //   console.log("Document successfully written!");
      //  })
      //  .catch((error) => {
      //   console.error("Error writing document: ", error);
      //   alert('Some problem. Please email office@21ifm.com')
      // });

  },

signin: (email, password, setErrors, setToken,setPaymentFlag) => {
    //change from create users to...
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      //everything is almost exactly the same as the function above
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b;
        //set token to localStorage
        await localStorage.setItem("token", token);
        let paymentChecked  = await isPaidUserTest();
        await setPaymentFlag(paymentChecked)
        await localStorage.setItem("paymentFlag", paymentChecked)
        setToken(window.localStorage.token);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },

forgotPassword: (email)=> {
    firebase.auth().sendPasswordResetEmail(email)
    .then(function () {
        alert('Please check your email...')
    }).catch(function (e) {
        console.log(e)
    })
  },
  //no need for email and password
  signout: (setErrors, setToken) => {
    // signOut is a no argument function
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //remove the token
        localStorage.removeItem("token");
        localStorage.removeItem("paymentFlag");
        //set the token back to original state
        setToken(null);
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => [...prev, err.message]);
        //whether firebase does the trick or not i want my user to do there thing.
        localStorage.removeItem("token");
        setToken(null);
        console.error(err.message);
        alert('signout error')
      });
      window.location.reload(false);
  },

   addUser: (fullname, email) => {
    const db = firebase.firestore();
    const userRef = db.collection("users").add({
      fullname: 'Mirla',
      email: 'Mirla@gmail.com'
    });  
  },

  handleVerifyEmail: (actionCode)=> {
    // https://firebase.google.com/docs/auth/custom-email-handler#web-v8_3
  },

  sendEmailLink: (email, setError, setToken) => {
    // not complete: https://github.com/firebase/quickstart-js/blob/master/auth/email-link.html

    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: "https://www.21ifm.com",
      // This must be true.
      handleCodeInApp: true
      // iOS: {
      //   bundleId: "com.example.ios"
      // },
      // android: {
      //   packageName: "com.example.android",
      //   installApp: true,
      //   minimumVersion: "12"
      // },
      // dynamicLinkDomain: "https://2t0v6.csb.app/"
    };
    // var email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }
    console.log("sent" + email);
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        alert("sent" + email);
        // ...
      })
      .catch((error) => {
        alert(error);
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
  }
};
