
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactPlayerComp from "../../components/ReactPlayerComp";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import GRCard from "../../components/GRCard";
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const items1 = [{
  title: 'Which distribution should be used to model asset prices?',
  subtitle: 'Log Normal distribution. Since the lognormal distribution is bound by zero on the lower side, it is perfect for modeling asset prices that cannot take negative values.'
}, {
  title: 'How is uncertainity in future prices modelled?',
  subtitle: 'Uncertainty is modelled by a probability density function.Its mean is assumed to approximate expected future price.'
}, {
  title: 'What is the required rate of return?',
  subtitle: "The required rate of return (RRR) is the minimum return an investor will accept for owning a company's stock, as compensation for a given level of risk associated with holding the stock."
}, {
  title: 'How do you calculate present value?',
  subtitle: 'Present value (PV) is the current value of a future sum of money or stream of cash flows given a specified rate of return. Future cash flows are discounted at the discount rate, and the higher the discount rate, the lower the present value of the future cash flows'
}];
export const items2 = [{
  title: 'Pricing financial assets',
  subtitle: 'Investors use present value to determine whether the price they pay for an investment is reasonable'
}, {
  title: 'Discount rates can be confusing.',
  subtitle: ' Most people immediately grasp the concept of compound growth. Discounting is merely the inverse of growing.'
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  Box1,
Text9,
Text14,
items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <h1>{`BASIC MATHEMATICS TO UNDERSTANF BLACK SCHOLES OPTIONS PRICING`}</h1>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/growth_intro.png
" mdxType="ImageCard" />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_story.png
" mdxType="ImageCard" />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/log_normal_asset.png
" mdxType="ImageCard" />
    <h1>{`Interactive`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/Wiener_Process_simulated_4.json" ggbParameters={ggbParameters} appId="wiener" email="pavanmirla@gmail.com" data={[{
      component: <Text14 mdxType="Text14" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }, {
      component: <Text14 mdxType="Text14" />,
      button: "f"
    }]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Basics"}/> */
      }
    </Container>
    <br />
    {
      /* sonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/pf2.json" */
    }

    <Container mdxType="Container">
  {
        /* <Content items={items2} title={"Where and how can I use this?"}/> */
      }
    </Container>
    <br />

    <h1>{`LOG NORMAL ASSET VALUES`}</h1>
    <GeogebraDraw jsonUrl=" https://storage.googleapis.com/chapters-ifm/testfolder/math/normalormal_relationship.json" ggbParameters={ggbParameters} appId="log_id" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;