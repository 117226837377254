
import  {useState,useEffect,useReducer,useRef} from "react";
import { Portal } from "react-portal";
import Draggable from 'react-draggable';


export default function OpenVideo (props) {
    const [isOpen, setIsOpen] = useState(false);
    function handleClick(d) {
          setIsOpen(!isOpen)
    }
    return (
      <>
         {isOpen && <div> <Portal>  
          <Draggable>
          <div className="modal" style={{position: 'fixed', top: 25, float :' right',  left: 10,   zIndex: 1000000, display: "flex"}} >
            <span style={{position: 'absolute',color: "white"}}>This window can be dragged </span>
           {/* {props.component} 
            */}
            {props.children}
           <button  style={{position: 'fixed', top: 5, float :' right', right: 5}} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "Close" : "Open "}
        </button>
        </div>
        </Draggable>
      </Portal>
       </div>}
      <button   onClick={() => setIsOpen(!isOpen)}> {isOpen ? "Close" : "Open "} Video
        </button>
    </>
     )
  }