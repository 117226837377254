
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import  { useContext , useState} from 'react';
import SP500SwarmPlot from "../../components/SP500SwarmPlot";
import { BuzzFeedQuiz } from "react-buzzfeed-quiz";
import  ReactIsotopeEX  from "../../components/ReactIsotope";
import "react-buzzfeed-quiz/lib/styles.css";
// import BackgroundImage from "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif";
export const NumberContext = React.createContext();
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const MyApp = () => {
  const [answers, setAnswers] = useState(null);

  const handleanswer = () => {};

  return <NumberContext.Provider value={(answers, setAnswers)}>
    <BuzzFeedQuiz title={"Quiz title"} description={"Attempt these even if you are new to Call Options. After you interact with our App, you will be able to answer all these questions ."} autoScroll={true} generalBackgroundColor={"gray"} generalFontColor={"gray"} onRestart={() => alert("This alert was triggered by the onRestart prop!")} onResult={() => {
      alert("The onResult prop triggered this alert!");
      debugger;
    }} onAnswerSelection={(questionIndex, answerIndex, resultID) => console.log({
      questionIndex,
      answerIndex,
      resultID
    })} questions={[{
      question: "Here is some adjacent image text",
      backgroundColor: "#ab47bc",
      fontColor: "#000",
      answerArrangement: "row",
      answers: [{
        answer: "What if this ia long answer choice?",
        resultID: 0,
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Wrong answer!
                        </h3>
                      </>,
          description: <>
                        <h3>
                          Wrong answer!
                        </h3>
                      </>
        }
      }, {
        answer: "Answer two",
        resultID: 1,
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Wrong answerr
                        </h3>
                      </>,
          description: <>
                        <h3>
                          Wrong answer!
                        </h3>
                      </>
        }
      }, {
        answer: "Answer three",
        resultID: 2,
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Here's an answer response!
                        </h3>
                      </>,
          description: <>
                        <h3>
                          Wrong answer!
                        </h3>
                    </>,
          image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
          imageAttribution: "Via Pexels"
        }
      }]
    }, {
      question: "Here is some adjacent image text",
      questionRelativeToImage: "adjacent",
      answerArrangement: "column",
      backgroundImageSrc: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
      answers: [{
        answer: "What if this ia long answer choice?",
        resultID: 0,
        backgroundImageSrc: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Wrong answer!
                        </h3>
                      </>,
          description: "Trigger your own answer response by setting an answer's revealResponse key. You can set a title and description value (either of which can be a string or a JSX element). You can also set an image and an image attribution statement."
        }
      }, {
        answer: "Answer two",
        resultID: 1,
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Wrong answerr
                        </h3>
                      </>,
          description: "Trigger your own answer response by setting an answer's revealResponse key. You can set a title and description value (either of which can be a string or a JSX element). You can also set an image and an image attribution statement."
        }
      }, {
        answer: "Answer three",
        resultID: 2,
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Here's an answer response!
                        </h3>
                      </>,
          description: <h1>
                      Trigger your own answer response by setting an answer's revealResponse key. You can set a title and description value (either of which can be a string or a JSX element). You can also set an image and an image attribution statement."
                     </h1>,
          image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
          imageAttribution: "Via Pexels"
        }
      }]
    }, {
      question: <> <Box1 mdxType="Box1" /></>,
      backgroundColor: "#ab47bc",
      fontColor: "#000",
      answerArrangement: "column",
      answers: [{
        answer: "What if this ia long answer choice?",
        resultID: 0,
        backgroundImageSrc: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
        revealResponse: {
          title: <>
                        <h3>
                          Wrong answer!
                        </h3>
                      </>,
          description: <>
                        <h3>
                          Wrong answer!
                        </h3>
                      </>
        }
      }, {
        answer: "Answer two",
        resultID: 1,
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Wrong answerr
                        </h3>
                      </>,
          description: <>
                        <h3>
                          Wrong answer!
                        </h3>
                      </>
        }
      }, {
        answer: "Answer three",
        resultID: 2,
        revealResponse: {
          title: <>
                        <h3 className="rbq_question_response_title">
                          Here's an answer response!
                        </h3>
                      </>,
          description: <>
                        <h3>
                          Wrong answer!
                        </h3>
                      </>,
          image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
          imageAttribution: "Via Pexels"
        }
      }]
    }]} results={[{
      title: "Your first result title goes here",
      description: "Your first result description goes here",
      imageAttribution: "Your photo attribution text goes here",
      resultID: 0
    }, {
      title: "Your second result title goes here",
      description: "Your second result description goes here",
      resultID: 1
    }, {
      title: "Your third result title goes here",
      description: "Result images and attributions are optional!",
      resultID: 2
    }]} mdxType="BuzzFeedQuiz" />
     </NumberContext.Provider>;
};
export const ggbParameters = {
  material_id: "aqwer",
  width: 1800,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '280',
  width: '500',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 2,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};

const layoutProps = {
  NumberContext,
meta,
MyApp,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
















    <MyApp mdxType="MyApp" />
    <ReactIsotopeEX mdxType="ReactIsotopeEX" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;